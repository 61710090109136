import React, { Component } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'

export default class PAccordion extends Component {
  state = { activeIndex: -1 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    return (
    <div id="accordionContainer">
      <h2>Hochwertige Leistungen im Überblick</h2>
      <p>Mit Deiner Fahrradabsicherung erhältst Du automatisch das Rundum-Sorglos-Fahrspaß-Paket mit der weltweit ersten grünen Fahrradabsicherung.</p>
      <Accordion styled>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' />
          Was abgesichert ist
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
            <ul>
                <li>Diebstahlschutz für Dein Fahrrad oder E-Bike</li>
                <li>Inklusive aller fest verschraubten Zubehöre wie Kindersitz</li>
                <li>24-Stunden-Deckung, keine Nachtklausel</li>
                <li>In Deutschland und unterwegs in Europa</li>
            </ul>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' />
          Was Du bekommst
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
            <ul>
                { this.props.showLock && <li>30 Euro zu Deinem neuen Fahrradschloss</li> }
                <li>Deine Beiträge werden nachhaltig angelegt</li>
                <li>Netzwerk nachhaltiger Fahrradläden</li>
                <li>Geld zurück nach drei Jahren ohne Schaden</li>
            </ul>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 2}
          index={2}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' />
          Sicherheit im Schadensfall
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 2}>
            <ul>
                <li>Schnelle Schadenabwicklung, persönlicher Ansprechpartner</li>
                <li>ÖPNV-Ticket für die Zwischenzeit</li>
                <li>Ersatzfahrrad für 20% mehr der abgesicherten Summe</li>
            </ul>
        </Accordion.Content>
      </Accordion>
    </div>
    )
  }
}
