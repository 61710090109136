/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

export default function CalloutModal(props) {
  const style = {
    content: {
      inset: 0,
      background: 'unset'
    }
  }
  return (
    <>
      <Modal
        isOpen = { props.active }
        style = { style }>
        <div>
          <p>Einen Augenblick bitte!</p>
        </div>
      </Modal>
    </>
  )
}
