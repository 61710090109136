/* eslint-disable jsx-a11y/anchor-is-valid */
/*
 * verde-react-slider - Berechnungsworkflow für ver.de bike
 * TechGenossen e.G.: Vicky, Jens, Peter
 */

import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Link } from "react-router-dom";
import { AnimatedRoute } from "react-router-transition";
import axios from "axios";
import {
  Input,
  Button,
  Dropdown,
  Form,
  Radio,
  Message,
  Card,
} from "semantic-ui-react";
import { round } from "mathjs";
import PSlider from "./components/slider2";
import PSliderpassive from "./components/slider2passive";
import PAccordion from "./components/accordion";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import CalloutModal from "./components/calloutModal";

export default function App() {
  const tamaBaseUrl = "https://tama.ver.de";
  const wooBaseUrl = "https://bike.ver.de";
  const [bikes, setBikes] = useState([
    {
      amount: "1",
      wert: 500,
      typ: "normal",
    },
  ]);
  let securePrice = 0;
  bikes.map((bike) => securePrice += parseFloat(bike.amount) * parseFloat(bike.wert));
  securePrice = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(securePrice);
  const removeBike = (index) => {
    let copy = [...bikes];
    copy.splice(index, 1);
    setBikes(copy);
  };
  const [modalOpen, setModalOpen] = useState(false);

  const getWooProductUrl = () => {
    return "/wp-json/tama/v1/product/request";
  };
  const [postData, setPostData] = useState();
  const wp_callout = () => {
    setModalOpen(true);
    let wp_data = { ...postData };
    wp_data.zahlungsweise = state.zahlung === "mon" ? "monatlich" : "jaehrlich";
    axios
      .post(wooBaseUrl + getWooProductUrl(), wp_data, { withCredentials: true })
      .then((response) => {
        let data = JSON.parse(response.data);
        console.log("response from wp dispatch", data);
        if (data.redirect_to) window.top.location.href = data.redirect_to;
      })
      .catch((e) => {
        console.error(e);
        setModalOpen(false);
      });
  };
  const [isMulti, setIsMulti] = useState(false);
  let [state, setState] = useState({
    plz: undefined,
    mitglied: false,
    preis_j: undefined,
    preis_m: undefined,
    rabattcode: "",
    error: undefined,
    updatePreis: undefined,
    calloutUrl: undefined,
    zahlung: "year",
  });
  const [updatePreis, setUpdatePreis] = useState(true);
  const { trackPageView } = useMatomo();
  const addBikes = () => {
    setBikes(bikes.concat({ amount: "1", wert: 500 }));
  };
  const debugState = false;

  const updateBike = (e, r, key, i) => {
    const copy = [...bikes];
    copy[i][key] = r.value;
    setBikes(copy);
    setUpdatePreis(true);
  };
  function update(e, result) {
    if (debugState) {
      console.log("e", e);
      console.log("result", result);
    }
    const { name, value } = result;
    setState(Object.assign({}, state, { [name]: value }));
    if (name !== "preis_j" && name !== "preis_m" && name !== "error")
      setUpdatePreis(true);
  }

  function handleup(index) {
    console.log("updating price for bike on index", index);
    if (bikes[index].wert >= 10000) {
      updateBike(undefined, { value: 10000 }, "wert", index);
    } else {
      if (bikes[index].wert < 500) {
        updateBike(undefined, { value: 500 }, "wert", index);
      } else {
        updateBike(
          undefined,
          { value: parseInt(bikes[index].wert) + 100 },
          "wert",
          index
        );
      }
    }
  }
  function handledown(index) {
    if (bikes[index].wert <= 500) {
      updateBike(undefined, { value: 500 }, "wert", index);
    } else {
      if (bikes[index].wert > 10000) {
        updateBike(undefined, { value: 10000 }, "wert", index);
      } else {
        updateBike(
          undefined,
          { value: parseInt(bikes[index].wert) - 100 },
          "wert",
          index
        );
      }
    }
  }

  const typoptions = [
    {
      key: "elektrisch",
      text: "E-Bike",
      value: "elektrisch",
    },
    {
      key: "normal",
      text: "Fahrrad",
      value: "normal",
    },
    {
      key: "lastenrad",
      text: "Lastenrad",
      value: "lastenrad",
    },
    {
      key: "elastenrad",
      text: "E-Lastenrad",
      value: "elastenrad",
    },
  ];

  const amountoptions = [
    {
      key: "1",
      text: "1",
      value: "1",
    },
    {
      key: "2",
      text: "2",
      value: "2",
    },
    {
      key: "3",
      text: "3",
      value: "3",
    },
    {
      key: "4",
      text: "4",
      value: "4",
    },
    {
      key: "5",
      text: "5",
      value: "5",
    },
    {
      key: "6",
      text: "6",
      value: "6",
    },
    {
      key: "7",
      text: "7",
      value: "7",
    },
    {
      key: "8",
      text: "8",
      value: "8",
    },
    {
      key: "9",
      text: "9",
      value: "9",
    },
    {
      key: "10",
      text: "10",
      value: "10",
    },
  ];
  function Plz() {
    // Achtung: label != Label. Label erweitert den Input, label ist außerhalb semantic-ui
    useEffect(() => {
      trackPageView();
    }, []);
    return (
      <div className="ui container">
        <h2>Los geht's. Wo wohnst Du?</h2>
        <div className="innerContainer">
          <label htmlFor="plzinput">Postleitzahl</label>
          <Input
            id="plzinput"
            name="plz"
            type="number"
            required={true}
            value={state.plz}
            autoFocus={true}
            onChange={(e, r) => update(e, r)}
          />
          <Button
            as={Link}
            to="/typ"
            disabled={!state.plz || state.plz.length !== 5}
          >
            Weiter
          </Button>
        </div>
      </div>
    );
  }

  function Typ() {
    useEffect(() => {
      trackPageView();
    }, []);
    return (
      <div className="ui container">
        <span className="backlink">
          <Link to="/">〈</Link>
        </span>
        <h2>
          {isMulti
            ? "Welche Fahrräder möchtest Du absichern?"
            : "Was für ein Fahrrad möchtest Du absichern?"}
        </h2>
        <div className="innerContainer">
          <Form>
            {isMulti && multiBikes()}
            {!isMulti && singleBike()}
            {isMulti === false && (
              <Button
                className="button-inverted large-button"
                as={Link}
                onClick={() => setIsMulti(true)}
              >
                Mehr Fahrräder?
              </Button>
            )}
            <Button as={Link} to="/haste" disabled={bikes.length === 0}>
              Weiter
            </Button>
          </Form>
        </div>
          <div id="spezial">
          Ihr habt eine spezielle Anfrage, die unser Standardformular nicht abdeckt (bsp. eine große Anzahl an Fahrrädern)? Kein Problem! Schreibt uns gerne eine Email an <a href="mailto:bike@ver.de">bike@ver.de</a> und wir prüfen, ob wir Euch ein passgenaues Angebot machen können.
        </div>

      </div>
    );
  }

  function singleBike() {
    return (
      <>
        <Dropdown
          fluid
          id="typselect"
          placeholder="Auswählen"
          name="typ"
          value={bikes[0].typ}
          onChange={(e, r) => updateBike(e, r, "typ", 0)}
          selection
          required
          options={typoptions}
        />
      </>
    );
  }
  function multiBikes() {
    return (
      <>
        {bikes.map((b, i) => {
          return (
            <div key={i}>
              <Form.Group>
                <Form.Field>
                  {i === 0 && <label>Anzahl</label>}
                  <Dropdown
                    className="two wide"
                    id="amountselect"
                    fluid
                    name="amount"
                    value={b.amount}
                    onChange={(e, r) => updateBike(e, r, "amount", i)}
                    selection
                    required
                    defaultValue={"1"}
                    options={amountoptions}
                  />
                </Form.Field>
                <Form.Field>
                  {i === 0 && <label>Fahrradtyp</label>}
                  <Dropdown
                    className="fourteen wide"
                    id="typselect"
                    placeholder="Auswählen"
                    name="typ"
                    value={b.typ}
                    onChange={(e, r) => updateBike(e, r, "typ", i)}
                    selection
                    required
                    options={typoptions}
                  />
                </Form.Field>
                <div className={i===0?"field trashcan1-pad":"field trashcan1"}>
                  <span className="emoji" onClick={() => removeBike(i)}>
                    &#128465;
                  </span>
                </div>
              </Form.Group>
            </div>
          );
        })}
        <Button
          as={Link}
          className="button-inverted"
          onClick={() => addBikes()}
        >
          + Fahrradtyp
        </Button>
      </>
    );
  }

  function Wert() {
    useEffect(() => {
      trackPageView();
    }, []);
    return (
      <div className="ui container">
        <h2>Welchen Wert möchtest Du absichern?</h2>
        <span className="backlink">
          <Link to="/typ">〈</Link>
        </span>
        {!isMulti && <PSlider wert={bikes[0].wert} update={updateBike} />}
        {isMulti && (
          <PSliderpassive
            wert={bikes.reduce((n, { amount, wert }) => n + amount * wert, 0)}
          />
        )}
        <div className="innerContainer ui form bike__items">
          {!isMulti && (
            <div className="wertinline">
              <Input
                id="wertinput"
                autoFocus={true}
                key="input"
                name="wert"
                required={true}
                label="€"
                type="number"
                min="500"
                max="10000"
                step="100"
                readOnly={true}
                value={bikes[0].wert}
                onChange={update}
              />
              <div className="wertbuttons">
                <Button
                  className="updownbutton upbutton"
                  onClick={() => handleup(0)}
                >
                  +
                </Button>
                <br />
                <Button
                  className="updownbutton downbutton"
                  onClick={() => handledown(0)}
                >
                  -
                </Button>
              </div>
            </div>
          )}
          {isMulti &&
            bikes.map((b, i) => {
              return (
                <div key={i} className="multi bike__item">
                  <Form.Group>
                  <Form.Field className="bike__item-count">
                      {i === 0 && <label>Anzahl</label>} 
                      <Dropdown
                        className="bike__item-dropdown"
                        key={"dda" + i}
                        name="amount"
                        value={b.amount}
                        onChange={(e, r) => updateBike(e, r, "amount", i)}
                        selection
                        required
                        options={amountoptions}
                      />
                    </Form.Field>
                    <Form.Field className="bike__item-type">
                      {i === 0 && <label>Fahrradtyp</label>}
                      <Dropdown 
                        className="bike__item-dropdown"
                        fluid
                        key={"ddt" + i}
                        placeholder="Auswählen"
                        name="typ"
                        value={b.typ}
                        onChange={(e, r) => updateBike(e, r, "typ", i)}
                        selection
                        required
                        options={typoptions}
                      />
                    </Form.Field>
                    <Form.Field className="bike__item-worth">
                      {i === 0 && <label>Fahrradwert</label>}
                      <div className="input-wrapper">
                      <Input
                        className="wertinput"
                        name="wert"
                        required={true}
                        label="€"
                        type="number"
                        min="500"
                        max="10000"
                        step="100"
                        value={b.wert}
                        readOnly={true}
                      />
                    <Form.Field className="btns">
                      <div className={i===0?"wertbuttons-multiple-pad":"wertbuttons-multiple"}>
                        <Button
                          className="updownbutton upbutton"
                          onClick={() => handleup(i)}
                        >
                          +
                        </Button>
                        <br />
                        <Button
                          className="updownbutton downbutton"
                          onClick={() => handledown(i)}
                        >
                          -
                        </Button>
                      </div>
                      </Form.Field>
                      </div>
                    </Form.Field>
                  </Form.Group>
                </div>
              );
            })}
            {isMulti && <div className="bike__items-result">
              <label>{ securePrice }</label>
            </div>}
        </div>
        <Button as={Link} to="/mitglied" disabled={bikes.length === 0}>
          Weiter
        </Button>

      </div>
    );
  }
  function Mitglied() {
    useEffect(() => {
      trackPageView();
    }, []);
    return (
      <div className="ui container">
        <h2>
          Bist Du schon ver.de Mitglied?
          <br />
          Oder hast einen Rabattcode?
        </h2>
        <div className="innerContainer">
          <div>
            <label htmlFor="mitgliedschaft">
              ver.de-Mitglied (
              <a
                href="https://geno.ver.de"
                rel="noopener noreferrer"
                target="_blank"
              >
                Erfahre mehr
              </a>
              )
            </label>
            <div id="radiobuttons">
              <Form.Group>
                <Radio
                  style={{ float: "left" }}
                  value={true}
                  label="Ja"
                  name="mitglied"
                  checked={state.mitglied}
                  onChange={update}
                />
                <Radio
                  style={{ float: "left", margin: "0 0 0 20px" }}
                  value={false}
                  label="Nein"
                  name="mitglied"
                  checked={!state.mitglied}
                  onChange={update}
                />
              </Form.Group>
            </div>
          </div>
          <span className="backlink">
            <Link to="/haste">〈</Link>
          </span>
          <div id="rabatt">
            <label htmlFor="rabattcode">Rabattcode (optional)</label>
            <Input
              autoFocus={true}
              required={false}
              name="rabattcode"
              value={state.rabattcode}
              onChange={update}
            />
            <Button as={Link} to="/rechneste">
              Weiter
            </Button>
          </div>
        </div>
      </div>
    );
  }

  function Berechnung() {
    useEffect(() => {
      trackPageView();
    }, []);
    if (state.plz && updatePreis === true) {
      setUpdatePreis(false);
      //setPostData({
      let sendPostData = {
        contract_type: (bikes.length === 1 && bikes[0].amount==='1') ? "single" : "group",
        plz: state.plz,
        cust_type: bikes.length === 1 ? "private" : "business",
        mitglied: "" + state.mitglied,
        discount_code: state.rabattcode,
        zahlungsweise: "year",
        bikes: bikes.map((b) => {
          return {
            qty: b.amount,
            wert: b.wert,
            typ: b.typ,
          };
        }),
      };
      let sendPostDataMon = {
        contract_type: (bikes.length === 1 && bikes[0].amount==='1') ? "single" : "group",
        plz: state.plz,
        cust_type: bikes.length === 1 ? "private" : "business",
        mitglied: "" + state.mitglied,
        discount_code: state.rabattcode,
        zahlungsweise: "monatlich",
        bikes: bikes.map((b) => {
          return {
            qty: b.amount,
            wert: b.wert,
            typ: b.typ,
          };
        }),
      };
      setPostData(sendPostData);
      console.log("POSTING", sendPostData);
      const calloutUrl = `${tamaBaseUrl}/calc`;
      const yearly = axios.post(calloutUrl,sendPostData);
      const monthly = axios.post(calloutUrl,sendPostDataMon);
      axios.all([yearly,monthly]).then(axios.spread((...responses) =>{
        if(responses[0].data ==="NaN" || responses[1]==="NaN"){
          update({
            name: "error",
            value:
              "Für diese Eingaben kann kein Preis berechnet werden: " +
              responses[0].data + " " + responses[1].data,
          });
        }
        else{
          const preisJaehrlich = responses[0].data;
          const preisMonthly = responses[1].data;
          console.log(responses);
          setState(
            Object.assign({}, state, {
              preis_j: Number.parseFloat(preisJaehrlich).toFixed(2),
              preis_m: Number.parseFloat(preisMonthly).toFixed(2),
            })
          );
        }
      })).catch((e) => {
        update(null, {
          name: "error",
          value:
            "Für diese Angaben konnte kein Preis berechnet werden: " +
            e.response.data,
        });
        console.log(e);
      });
      /*
      axios
        .post(calloutUrl, sendPostData)
        .then((response) => {
          if (response.data === "NaN") {
            update({
              name: "error",
              value:
                "Für diese Eingaben kann kein Preis berechnet werden: " +
                response.data,
            });
          } else {
            const preisJaehrlich = response.data;
            setState(
              Object.assign({}, state, {
                preis_j: Number.parseFloat(preisJaehrlich).toFixed(2),
                preis_m: Number.parseFloat(
                  round(preisJaehrlich / 12 + 3.5, 2)
                ).toFixed(2),
              })
            );
          }
        })
        .catch((e) => {
          update(null, {
            name: "error",
            value:
              "Für diese Angaben konnte kein Preis berechnet werden: " +
              e.response.data,
          });
          console.log(e);
        });*/
    }
    const totalWert = bikes.reduce((accumulator, bike) => accumulator + bike.wert, 0);
    const carditems = [
      {
        key: 1,
        children: (
          <div
            className="content"
            onClick={(e) => update(null, { name: "zahlung", value: "mon" })}
          >
            <div className="header">{state.preis_m + " €"}</div>
            <div className="meta">Monatlich</div>
            <div className="description">
              {"Du sparst damit " +
                round((state.preis_j / 12) * 2, 0).toFixed(0) +
                " Kilogramm CO2 im Monat."}
            </div>
            <Radio
              name="zahlung"
              className="zahlradio"
              value="mon"
              checked={state.zahlung === "mon"}
            />
          </div>
        ),
      },
      {
        key: 2,
        children: (
          <div
            className="content"
            onClick={(e) => update(e, { name: "zahlung", value: "year" })}
          >
            <div className="header">{state.preis_j + " €"}</div>
            <div className="meta">Jährlich</div>
            <div className="description">
              <strong>
                {"Entspricht monatlich " +
                  round(state.preis_j / 12, 2).toFixed(2) +
                  " €."}
              </strong>
              <br />
              {"Du sparst damit " +
                round(state.preis_j * 2, 0).toFixed(0) +
                " Kilogramm CO2 im Jahr."}
            </div>
            <Radio
              defaultChecked
              name="zahlung"
              className="zahlradio"
              value="year"
              checked={state.zahlung === "year"}
            />
          </div>
        ),
      },
    ];
    
if(totalWert>=2000){
  carditems.shift();
}  
   
  
    return (
      <div className="ui container">
        <h2>Unser Vorschlag für Deine ver.de Fahrradabsicherung:</h2>
        {!state.plz && (
          <Message warning>
            <p>
              Du hast leider keine <Link to="/">Postleitzahl</Link> angegeben.
            </p>
          </Message>
        )}
        {state.error && (
          <Message error>
            <p>{JSON.stringify(state.error)}</p>
            <p>
              Korrigieren: <Link to="/wohnste">Postleitzahl</Link>,{" "}
              <Link to="/haste">Preis</Link>
            </p>{" "}
          </Message>
        )}
        <span className="backlink">
          <Link to="/mitglied">〈</Link>
        </span>

        <div>
          {state.preis_m && state.preis_j && <Card.Group items={carditems} />}
        </div>
        <a href="#" className="ui button" onClick={() => wp_callout()}>
          Weiter
        </a>
        <PAccordion showLock={bikes?.length <= 1} />
        <CalloutModal active={modalOpen} />
      </div>
   
    );
  }
  return (
    <Router>
      <div>
        <Switch>
          <AnimatedRoute
            exact
            path="/"
            component={Plz}
            atEnter={{ offset: -100 }}
            atLeave={{ offset: -100 }}
            atActive={{ offset: 0 }}
            mapStyles={(styles) => ({
              transform: `translateX(${styles.offset}%)`,
            })}
          ></AnimatedRoute>
          <AnimatedRoute
            path="/haste"
            component={Wert}
            atEnter={{ offset: -100 }}
            atLeave={{ offset: -100 }}
            atActive={{ offset: 0 }}
            mapStyles={(styles) => ({
              transform: `translateX(${styles.offset}%)`,
            })}
          ></AnimatedRoute>
          <AnimatedRoute
            path="/typ"
            component={Typ}
            atEnter={{ offset: -100 }}
            atLeave={{ offset: -100 }}
            atActive={{ offset: 0 }}
            mapStyles={(styles) => ({
              transform: `translateX(${styles.offset}%)`,
            })}
          ></AnimatedRoute>
          <AnimatedRoute
            path="/rechneste"
            component={Berechnung}
            atEnter={{ offset: -100 }}
            atLeave={{ offset: -100 }}
            atActive={{ offset: 0 }}
            mapStyles={(styles) => ({
              transform: `translateX(${styles.offset}%)`,
            })}
          ></AnimatedRoute>
          <AnimatedRoute
            path="/mitglied"
            component={Mitglied}
            atEnter={{ offset: -100 }}
            atLeave={{ offset: -100 }}
            atActive={{ offset: 0 }}
            mapStyles={(styles) => ({
              transform: `translateX(${styles.offset}%)`,
            })}
          ></AnimatedRoute>
        </Switch>
        {debugState && (
          <Message info id="debug">
            Debug state
            <br />
            <code>
              {JSON.stringify(state)}I shall update prices:{" "}
              {JSON.stringify(updatePreis)}
              {JSON.stringify(bikes)}
            </code>
          </Message>
        )}
      </div>
    </Router>
  );
}
