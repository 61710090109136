import React from 'react';
import ReactSlider from 'react-slider';

export default function PSlider(props) {
    return (
        <ReactSlider
            className="horizontal-slider"
            onAfterChange={v=>{props.update(undefined,{value:v},'wert',0)}}       
            defaultValue={props.wert}
            min={500}
            max={10000}
            step={100}
            renderThumb={(props,state) => <div id='slideval' {...props}>{state.valueNow} €</div>}
            />
    )
}