import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-less/semantic.less';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: "https://f9f31b949f8c4c5d8220631f868fe44b@sentry.kornherr.it/2",
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const instance = createInstance({
  urlBase: 'https://piwik.ver.de/piwik/',
  siteId: 15,
  configurations: {
    disableCookies: true,
    setSecureCookie: true
  }
});

ReactDOM.render(
  <React.StrictMode>
    <MatomoProvider value={ instance }>
      <App />
    </MatomoProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
